import React from 'react'
import { Button, logger, Select, TextInput, View } from '@/app'
import { Page } from '@/shared'
import { OptionsToQueryString, useAppSelector } from '@/lib'
import { useState } from 'react'
import {
  deepEqual,
  deepGet,
  onMount,
  onUpdate,
  traverse,
} from '@codeleap/common'
import { useUnmount } from 'react-use'
import { QueryToCategorySelect } from '@/lib/utils/QueryStringToOptions'
import { CategoryTypes } from '@/types'

type CategoriesProps = {
  optionKey: string
  setConditions: React.Dispatch<
    React.SetStateAction<Record<string, CategoryTypes.Filter>>
  >
  optionOrCategory: CategoryTypes.Category | CategoryTypes.Option
  index: number
  initialState?: Record<string, CategoryTypes.Filter>
}

function formatTitle(title: string) {
  return title.replace(/\s/g, '_').toLowerCase()
}

function matchCondition(key: string, value: string, condition: string) {
  const matchKey = `${formatTitle(key)}=${value}`
  if (condition.includes('clothing_type')) {
    console.log(condition, matchKey)
  }
  return condition.includes(matchKey)
}

const Categories: React.FC<CategoriesProps> = (props) => {
  const { optionOrCategory, optionKey, index, setConditions, initialState } =
    props
  const initialSelected = initialState?.[optionOrCategory.title]?.selects?.[0]
  const [selected, setSelected] = useState(() => initialSelected || null)
  const [textValue, setTextValue] = useState(
    () => initialState?.[optionOrCategory.title]?.value || '',
  )
  const { options } = useAppSelector((store) => store.Categories)

  onMount(() => {
    setConditions((prev) => {
      const newConditions: Record<string, CategoryTypes.Filter> = {
        ...prev,
        [optionOrCategory.title]: initialState?.[optionOrCategory.title] || {
          option: optionOrCategory.title,
          selects: initialSelected ? [initialSelected] : [],
          selectValues: optionOrCategory.selects,
        },
      }

      if (!['SG', 'MT'].includes(optionOrCategory.type)) {
        newConditions[optionOrCategory.title].value =
          initialState?.[optionOrCategory.title]?.value || ''
      }

      return newConditions
    })
  })

  useUnmount(() => {
    setConditions((prev) => {
      const copy = { ...prev }
      delete copy[optionOrCategory.title]
      return copy
    })
  })

  const handleChange = (value) => {
    setSelected(value)
    setConditions((prev) => {
      return {
        ...prev,
        [optionOrCategory.title]: {
          ...prev[optionOrCategory.title],
          option: optionOrCategory.title,
          selects: [value],
        },
      }
    })
  }

  const currentSelect = optionOrCategory.selects.find(
    (s) => s.id === selected,
  )?.title
  const subOptions: CategoryTypes.Option[] = currentSelect
    ? options.filter((o) => {
      const match = matchCondition(optionKey, currentSelect, o.condition)

      return match
    })
    : []

  switch (optionOrCategory?.type || 'SG') {
    case 'SG':
      return (
        <>
          <Select
            value={selected}
            onValueChange={handleChange}
            options={optionOrCategory.selects.map((s) => ({
              label: s.title,
              value: s.id,
            }))}
            placeholder={`Select an option`}
            label={optionOrCategory.title}
          />
          {subOptions.map((o, idx) => {
            const key = index + idx + 1
            return (
              <Categories
                index={key}
                key={`${o.id}-${key}`}
                initialState={initialState}
                optionKey={o.title}
                setConditions={setConditions}
                optionOrCategory={o}
              />
            )
          })}
        </>
      )
    case 'MT':
      return null
    default:
      return (
        <TextInput
          label={optionOrCategory.title}
          value={textValue}
          onChangeText={(val) => {
            setTextValue(val)
            setConditions((prev) => ({
              ...prev,
              [optionOrCategory.title]: {
                ...prev[optionOrCategory.title],
                value: val,
              },
            }))
          }}
        />
      )
  }
}

export default function Test() {
  const { categories, options } = useAppSelector((store) => store.Categories)
  const [conditions, setConditions] = useState<
    Record<string, CategoryTypes.Filter>
  >({})
  const [test, setTest] = useState(null)
  function onSubmit() {
    setTest(() => null)

    const queryString = OptionsToQueryString(conditions)

    logger.log({ queryString })

    const generated = QueryToCategorySelect(queryString, options, categories)
    setTimeout(() => {
      setTest(() => generated)
    }, 500)
  }

  return (
    <Page>
      <View variants={['column']}>
        <View css={styles}>
          {categories.length && options.length ? (
            <Categories
              optionKey='category'
              optionOrCategory={{
                title: 'Category',
                selects: categories,
                condition: '',
                type: 'SG',
              }}
              index={0}
              setConditions={setConditions}
            />
          ) : null}
        </View>
        <Button onPress={onSubmit} text='Submit' />
        <View css={styles}>
          {test ? (
            <Categories
              optionKey='category'
              optionOrCategory={{
                title: 'Category',
                selects: categories,
                condition: '',
                type: 'SG',
              }}
              initialState={test}
              index={0}
              setConditions={setTest}
            />
          ) : null}
        </View>
      </View>
    </Page>
  )
}

const styles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5,1fr)',
  gap: '1em',
  padding: 50,
}
